import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Popconfirm } from 'antd'
import { CashierStore } from 'store/cashier.store'
import Icon from 'shared/components/icon'
import { getNumberDelimiterAgnostic } from 'shared/utils/number'
import './product-checkout.scss'
import { FinancesStore } from 'store/finances.store'
import styled from 'styled-components'
import { isNegative } from 'shared/utils/isNegative'

const IconGift = styled.div`
  cursor: pointer;
  align-self: center;
  margin-top: 4px;
  width: 35px;
  text-align: center;
`

const Ekis = styled.div``

interface ProductCheckoutProps  {
  id: string
  quantity: number
  price: number
  title: string
}

export function ProductCheckout({ id, title, price, quantity }: ProductCheckoutProps) {
  const { multiply, divide } = FinancesStore
  const [showDelete, setShowDelete] = useState<boolean>(false)
  const [defaultSum, setDefaultSum] = useState<string>(price.toString())

  const [giftQuantity, setGiftQuantity] = useState<string>('0')

  const [defaultQuantity, setDefaultQuantity] = useState<string>(quantity.toString())

  const [showGift, setShowGift] = useState<boolean>(false)

  const setQuantity = useCallback(
    (q: number) => {
      if (!id) throw new Error('No product ID provided.')
      CashierStore.setQuantity(id, q)
    },
    [id]
  )

  useEffect(() => {
    if (quantity === 0) {
      setShowDelete(true)
    } else {
      setShowDelete(false)
    }
    multiply(quantity, price).then((sum) => {
      setDefaultSum(sum.toString())
    })
    setDefaultQuantity(quantity.toString())
  }, [quantity, price, multiply])

  return (
    <div className="product-checkout">
      <Popconfirm
        title={`Delete product: ${title}?`}
        onCancel={() => {
          setShowDelete(false)
        }}
        onConfirm={() => {
          if (id) {
            CashierStore.removeFromCheckout(id)
          }
        }}
      >
        <div
          tabIndex={-1}
          role="button"
          onKeyDown={() => setShowDelete(true)}
          onClick={() => setShowDelete(true)}
          className={`product-checkout__action product-checkout__action_remove ${
            showDelete ? 'product-checkout__action_remove-active' : ''
          }`}
        >
          <Icon name="cross" size={14} color="#ff0000" />
        </div>
      </Popconfirm>

      <div
        tabIndex={-1}
        role="button"
        onKeyDown={() => CashierStore.decreaseQuantity(id)}
        onClick={() => CashierStore.decreaseQuantity(id)}
        className="product-checkout__action"
      >
        <Icon name="minus" size={14} />
      </div>
      <div className="product-checkout__title">
        <div className="product-checkout__title-text">{title}</div>
      </div>
      <div className="product-checkout__price">
        <div className="product-checkout__price-text">{price} cred.</div>
      </div>
      <div className="product-checkout__quantity">
        <Ekis>x</Ekis>
        <input
          className="product-checkout__quantity-input"
          value={defaultQuantity}
          onChange={(e) => {
            setDefaultQuantity(e.target.value)
          }}
          onBlur={async (e) => {
            const q = getNumberDelimiterAgnostic(e.target.value)
            setQuantity(q)
            const sum = await multiply(q, price)
            setDefaultSum(sum.toString())
          }}
        />
      </div>

      <div className="product-checkout__total">
        <input
          className="product-checkout__sum-input"
          value={defaultSum}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setDefaultSum(e.target.value)
          }}
          onBlur={async (e) => {
            const { value } = e.target
            
            if (isNegative(parseFloat(value))) {
              setDefaultSum('0')
              return
            }

            const q = await divide(getNumberDelimiterAgnostic(value), price)
            setQuantity(q)
          }}
        />
      </div>

      <div className="product-checkout__gift">
        {showGift ? (
          <input
            className="product-checkout__gift-input"
            value={giftQuantity}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setGiftQuantity(e.target.value)
            }}
            onBlur={async (e: ChangeEvent<HTMLInputElement>) => {
              CashierStore.setGiftQuantity(id, getNumberDelimiterAgnostic(e.target.value))
            }}
          />
        ) : (
          <IconGift>
            <Icon name="gift" size={18} color="#babababa" onClick={() => setShowGift(true)} />
          </IconGift>
        )}
      </div>
      <div
        tabIndex={-1}
        role="button"
        onKeyDown={() => CashierStore.increaseQuantity(id)}
        onClick={() => CashierStore.increaseQuantity(id)}
        className="product-checkout__action product-checkout__action_plus"
      >
        <Icon name="plus" size={14} />
      </div>
    </div>
  )
}
