import React, { useState } from 'react'
import styled from 'styled-components'
import { RechargeBatchModal } from './recharge-batch.modal'
import { RechargeBatchTriggerProps } from './types'

const Trigger = styled.span`
  display: inline-block;
`

export function RechargeBatchTrigger({
  onSubmit,
  children,
  productTitle,
}: RechargeBatchTriggerProps) {
  const [show, setShow] = useState<boolean>(false)
  return (
    <div>
      {show && (
        <RechargeBatchModal
          productTitle={productTitle}
          onSubmit={onSubmit}
          onClose={() => {
            setShow(false)
          }}
        />
      )}
      <Trigger onClick={() => setShow(true)}>{children}</Trigger>
    </div>
  )
}
