import React, { useState } from 'react'
import styled from 'styled-components'
import { RegisterWasteModal } from './register-waste.modal'
import { RegisterWasteTriggerProps } from './types'

const Trigger = styled.span`
  display: inline-block;
`

export function RegisterWasteTrigger({
  onSubmit,
  children,
  productTitle,
}: RegisterWasteTriggerProps) {
  const [show, setShow] = useState<boolean>(false)
  return (
    <div>
      {show && (
        <RegisterWasteModal
          productTitle={productTitle}
          onSubmit={onSubmit}
          onClose={() => {
            setShow(false)
          }}
        />
      )}
      <Trigger onClick={() => setShow(true)}>{children}</Trigger>
    </div>
  )
}
