import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Col, Input, InputRef, Row } from 'antd'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'shared/components/modal'
import { Button } from 'shared/components'
import { startsWithZeroOrNegative } from 'shared/utils/isNegative'

const Warning = styled.div`
  padding: 3px;
  color: white;
  background: red;
  margin-top: 10px;
  font-weight: 700;
`

interface ChangeBalanceModalProps {
  onSubmit: (amount: number) => void
  onClose: () => void
  title?: string
  buttonText?: string
  defaultAmount?: number
}

export function ChangeBalanceModal({
  onSubmit,
  onClose,
  title,
  buttonText,
  defaultAmount,
}: ChangeBalanceModalProps) {
  const [amount, setAmount] = useState<string>(defaultAmount?.toString() ?? '')
  const maxAmount = 300;

  const moreThanMaxAmmount = parseFloat(amount) > maxAmount

  const isDisabled = startsWithZeroOrNegative(amount) || moreThanMaxAmmount

  const [increasing, setIncreasing] = useState<boolean>(false)

  const inputRef = useRef<InputRef>(null)

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus()
    }
  })

  return (
    <Modal>
      <ModalHeader onClose={onClose}>{title}</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={8}>Amount:</Col>
          <Col xs={16}>
            <Input
              disabled={increasing}
              ref={inputRef}
              size="large"
              value={amount}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setAmount(e.target.value)
              }}
            />
            {startsWithZeroOrNegative(amount) && (
              <Warning>
                Cannot start with zero and negative value, only positive values eg. 0.3 , 1, 25, are
                allowed.
              </Warning>
            )}

            {moreThanMaxAmmount && (
              <Warning>
                Maximum amount of donation is {maxAmount}, please enter less amount.
              </Warning>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={isDisabled}
          primary
          onClick={() => {
            setIncreasing(true)
            const numberAmount = parseFloat(amount.replace(/,/g, '.'))
            onSubmit(numberAmount)
          }}
        >
          {buttonText}
        </Button>
        <Button danger onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}
