import * as React from 'react'
import { Button } from 'shared/components'
import './terms-and-conditions.scss'
import { useRef, useState } from 'react'

interface TermsAndConditionsFeatureProps {
  setApprove: (approve: boolean) => void
  setDecline: (approve: boolean) => void
}

function TermsAndConditionsFeature({ setApprove, setDecline }: TermsAndConditionsFeatureProps) {
  const [termsRed, setTermsRed] = useState<boolean>(true)
  const listInnerRef = useRef<HTMLDivElement>(null)
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight === scrollHeight) {
        setTermsRed(true)
      }
    }
  }
  return (
    <div className="terms-and-conditions">
      <div className="terms-and-conditions__body" ref={listInnerRef} onScroll={onScroll}>
        <iframe
          title="contrato"
          src="https://drive.google.com/viewerng/viewer?embedded=true&url=https://coresocial.app/contrato-digital.pdf"
          width="100%"
          style={{ height: '99%', border: 0 }}
        />
      </div>
      <div className="terms-and-conditions__footer">
        <div className="terms-and-conditions__actions">
          <Button disabled={!termsRed} primary onClick={() => setApprove(true)}>
            Approve
          </Button>
          <Button bordered onClick={() => setDecline(false)}>
            Decline
          </Button>
        </div>
      </div>
    </div>
  )
}

export default TermsAndConditionsFeature
