import React from 'react'
import { useDelayUnmount } from 'shared/hooks'
import styled, { css, keyframes } from 'styled-components'

interface MountTrantisionStyledProps {
  isMounted: boolean
}

interface MountTrantisionProps extends MountTrantisionStyledProps {
  children: any
}

const hideTransition = keyframes`
 0% { opacity: 1 }
 30% { opacity: .5 }
 40% { opacity: .3; }
 100% { opacity: 0; }
`

const showTransition = keyframes`
 0% { opacity: 0 }
 30% { opacity: .3 }
 40% { opacity: .5; }
 100% { opacity: 1; }
`

const animationShow = css(['', ' 0.3s linear;'] as any as TemplateStringsArray, showTransition)

const animationHide = css(['', ' 0.3s linear;'] as any as TemplateStringsArray, hideTransition)

const Container = styled.div<MountTrantisionStyledProps>`
  width: 100%;
  height: 100%;
  animation: ${({ isMounted }) => (isMounted ? animationShow : animationHide)};
`

export function MountTransition({ children, isMounted }: MountTrantisionProps) {
  const shouldMount = useDelayUnmount(isMounted, 500)
  return shouldMount ? <Container isMounted={isMounted}>{children}</Container> : null
}
