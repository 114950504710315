import React, { ChangeEvent, useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import styled from 'styled-components'
import { StoreBatchFormData } from './types'

interface StoreBatchFormProps {
  setFormData: (formData: StoreBatchFormData) => void
  formData?: StoreBatchFormData
}

const FormRow = styled.div`
  width: 100%;
  margin-bottom: 15px;
`
const Input = styled.input`
  width: 100%;
  font-size: 16px;
  padding: 5px;
`

export function StoreBatchForm({ setFormData, formData }: StoreBatchFormProps) {
  const [price, setPrice] = useState<string>('0')
  const [quantity, setQuantity] = useState<string>('0')

  useEffect(() => {
    setFormData({
      price,
      quantity,
    })
  }, [price, quantity, setFormData])

  useEffect(() => {
    setPrice(formData?.price ?? '0')
    setQuantity(formData?.quantity ?? '0')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form>
      <FormRow>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="price">
          <Row>
            <Col xs={8}>Price of product</Col>
            <Col xs={16}>
              <Input
                id="price"
                value={price}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setPrice(e.target.value)
                }}
              />
            </Col>
          </Row>
        </label>
      </FormRow>

      <FormRow>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="quantity">
          <Row>
            <Col xs={8}>Quantity of product</Col>
            <Col xs={16}>
              <Input
                id="quantity"
                value={quantity}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setQuantity(e.target.value)
                }}
              />
            </Col>
          </Row>
        </label>
      </FormRow>
    </form>
  )
}
