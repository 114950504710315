import { makeAutoObservable } from 'mobx'
import * as R from 'ramda'
import { Effect } from './utils/Effect'
import { RequestHelper } from './utils/RequestHelper'
import {
  MemberQuote,
  MemberQuoteJournalReadDto,
  MemberQuoteJournalType,
  Order,
  ReadMemberVisitDto,
  ReadWarehouseJournalDto,
} from '../api-client'
import ApiClient from '../api'
import { AreasStore } from './areas.store'

class ReportsStoreClass {
  constructor() {
    makeAutoObservable(this)
  }

  /**
   * Effects
   */

  loadVisitsEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().reposts.postApiV1ReportsVisitsByPeriod(request)
      ),
    'Loading Visits',
    true
  )

  loadDepositTransactionsByUserEffect = new Effect((userId) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().reposts.getApiV1ReportsBalanceTransactionsDeposit(
        userId,
        AreasStore.selectedAreaId
      )
    )
  )

  getTodayRegistrationsEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().reposts.postApiV1ReportsNewMembersByPeriod(request)
      ),
    'Loading New Members Count',
    true
  )

  getTodayMemberQuoteJournalEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().reposts.postApiV1ReportsMemberQuoteJournal(request)
      ),
    'Loading New Members Count',
    true
  )

  getTodayWarehouseJournalByMemberEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().warehouse.postApiV1WarehouseJournalByMember(request)
      ),
    '',
    true
  )

  loadMemberBalanceMovementsEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().reposts.postApiV1ReportsMemberBalanceMovements(request)
      ),
    'Loading Product Orders',
    true
  )

  loadMemberBalanceMovements = (from: string, to: string, memberId: string) =>
    this.loadMemberBalanceMovementsEffect.run({
      from,
      to,
      memberId,
      areaId: AreasStore.selectedAreaId,
    })

  get memberBalanceMovements() {
    return this.loadMemberBalanceMovementsEffect.data?.slice() || []
  }

  setMemberBalanceMovements(orders: Array<Order>) {
    this.loadMemberBalanceMovementsEffect.data = orders
  }

  get memberBalanceMovementsLoading() {
    return this.loadMemberBalanceMovementsEffect.isLoading
  }

  loadMemberQuoteJournalEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().reposts.postApiV1ReportsMemberQuoteJournal(request)
      ),
    'Loading Product Orders',
    true
  )

  loadMemberQuoteJournal = (from: string, to: string, memberId: string) =>
    this.loadMemberQuoteJournalEffect.run({
      from,
      to,
      memberId,
      areaId: AreasStore.selectedAreaId,
    })

  get memberQuoteJournal() {
    return this.loadMemberQuoteJournalEffect.data?.slice() || []
  }

  get memberQuoteJournalLoading() {
    return this.loadMemberQuoteJournalEffect.isLoading
  }

  /**
   * Actions
   */
  getTodayWarehouseJournalByMember = (from: string, to: string, memberId: string) =>
    this.getTodayWarehouseJournalByMemberEffect.run({
      from,
      to,
      memberId,
      areaId: AreasStore.selectedAreaId,
    })

  get todayWarehouseJournalByMember() {
    return this.getTodayWarehouseJournalByMemberEffect.data?.slice() || []
  }

  setTodayWarehouseJournalByMember(data: Array<ReadWarehouseJournalDto>) {
    this.getTodayWarehouseJournalByMemberEffect.data = data
  }

  addTodayWarehouseJournalItem(data: ReadWarehouseJournalDto) {
    this.getTodayWarehouseJournalByMemberEffect?.data?.push(data)
  }

  get todayWarehouseJournalByMemberLoading() {
    return this.getTodayWarehouseJournalByMemberEffect.isLoading
  }

  getTodayRegistrations = (from: string, to: string) =>
    this.getTodayRegistrationsEffect.run({
      from,
      to,
      areaId: AreasStore.selectedAreaId,
    })

  getTodayMemberQuoteJournal = (from: string, to: string) =>
    this.getTodayMemberQuoteJournalEffect.run({
      from,
      to,
      areaId: AreasStore.selectedAreaId,
    })

  get todayMemberQuoteJournal(): Array<MemberQuoteJournalReadDto> {
    return this.getTodayMemberQuoteJournalEffect.data?.slice() || []
  }

  get todayMemberQuoteNoReturns(): Array<MemberQuoteJournalReadDto> {
    return this.todayMemberQuoteJournal.filter(
      // eslint-disable-next-line no-underscore-dangle
      (i) => i.type !== MemberQuoteJournalType._3 && i.revertable
    )
  }

  get todayMemberQuotesByGroup() {
    return this.todayMemberQuoteNoReturns.reduce((acc, curr) => {
      const key = curr.memberQuote?.title as string
      if (!acc[key]) {
        acc[key] = [curr.memberQuote]
      } else {
        acc[key].push(curr.memberQuote)
      }
      return acc
    }, {} as Record<string, Array<MemberQuote | undefined>>)
  }

  get todayMemberQuoteSum() {
    return Object.values(this.todayMemberQuotesByGroup).reduce((acc, curr) => {
      // eslint-disable-next-line no-unsafe-optional-chaining, no-param-reassign
      acc += curr.reduce((acc2, c) => {
        // eslint-disable-next-line no-unsafe-optional-chaining, no-param-reassign
        acc2 += c?.price!
        return acc2
      }, 0)
      return acc
    }, 0)
  }

  get todayRegistrationsCount() {
    return this.getTodayRegistrationsEffect.data ?? 0
  }

  getDepositTransactionsByUser = (userId: string) =>
    this.loadDepositTransactionsByUserEffect.run(userId)

  public loadVisits = async (from?: string, to?: string) => {
    const request = {
      from,
      to,
      areaId: AreasStore.selectedAreaId,
    }
    const response = await this.loadVisitsEffect.run(request)
    return response
  }

  public updateVisitItem = (id?: string, data?: ReadMemberVisitDto) => {
    const visitIndex = this.loadVisitsEffect.data?.findIndex((i) => i.id === id)
    if (data && visitIndex !== undefined && visitIndex !== -1 && !!this.loadVisitsEffect.data) {
      this.loadVisitsEffect.data[visitIndex] = data
    }
  }

  loading: boolean = this.loadVisitsEffect.isLoading

  startLoading = () => {
    this.loading = true
  }

  stopLoading = () => {
    this.loading = false
  }

  public get visits() {
    return this.loadVisitsEffect.data?.slice() || []
  }

  public get activeVisits() {
    return this.visits?.filter((v) => v.isActive).length || 0
  }

  public get totalUniqueVisitsCount() {
    return R.pipe(
      R.uniqBy((i: ReadMemberVisitDto) => i?.member?.id),
      R.length
    )(this.visits)
  }

  loadConsumptionByCategoryEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().reposts.postApiV1ReportsConsumptionByCategory(request)
      ),
    'Loading Product Orders',
    true
  )

  loadConsumptionByCategory = (from: string, to: string) =>
    this.loadConsumptionByCategoryEffect.run({
      from,
      to,
      areaId: AreasStore.selectedAreaId,
    })

  get consumptionByCategory() {
    return this.loadConsumptionByCategoryEffect.data?.slice() || []
  }

  loadTopConsumptionMemberEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().reposts.postApiV1ReportsBestMemberConsumption(request)
      ),
    'Loading Product Orders',
    true
  )

  loadTopConsumptionMember = (from: string, to: string) =>
    this.loadTopConsumptionMemberEffect.run({
      from,
      to,
      areaId: AreasStore.selectedAreaId,
    })

  get topConsumptionMember() {
    return this.loadTopConsumptionMemberEffect.data || {}
  }

  loadIncomeForMonthEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().reposts.postApiV1ReportsMoneyIncome(request)
      ),
    'Loading Product Orders',
    true
  )

  loadIncomeForMonth = (from: string, to: string) =>
    this.loadIncomeForMonthEffect.run({
      from,
      to,
      areaId: AreasStore.selectedAreaId,
    })

  get incomeForMonth() {
    return this.loadIncomeForMonthEffect.data?.slice() || []
  }

  get incomeForMonthDataSet() {
    return this.incomeForMonth.map((i) => i.sum)
  }

// GET BATCHES ARRIVAL REPORT //

getBatchesTotalArrivalEffect = new Effect(
  (request) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().reposts.postApiV1ReportsBatchesArival(request)
    ),
  'Loading',
  true
)


getBatchesTotalArrival = (from: string, to: string) =>
this.getBatchesTotalArrivalEffect.run({
  from,
  to,
  areaId: AreasStore.selectedAreaId,
})

get batchesTotalArrival(){
return this.getBatchesTotalArrivalEffect.data?.slice() || []
}


// END GET BATCHES ARRIVAL REPORT //

  loadSalesReportEffect = new Effect(
      (request) =>
          RequestHelper.unwrapFromFetchResponse(
              ApiClient().reposts.postApiV1ReportsSales(request)
          ),
      'Loading Sales Report',
      true
  )

  loadSalesReport = (from: string, to: string) =>
      this.loadSalesReportEffect.run({
        from,
        to,
        areaId: AreasStore.selectedAreaId,
      })

    get salesReport(){
      const list = this.loadSalesReportEffect.data?.map(x => ({
          ...x,
            key: x.id,
      }))
        return list?.slice() || []
    }

}

export const ReportsStore = new ReportsStoreClass()
