import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import './NavigationItemChild.scss'
import { AppStore } from '../../../store/app.store'

interface NavigationItemChildProps {
  to: string
  title: string
}

export function NavigationItemChild({ to, title }: NavigationItemChildProps) {
  const { pathname } = useLocation()
  const isActive = pathname.includes(to.replace(/\/$/, ''))

  const clickHandler = () => {
    if (isMobile) {
      AppStore.setSidebarHidden()
    }
  }

  return (
    <div
      className={`
      navigation-item-child 
      ${isActive ? 'navigation-item-child_active' : ''}
      `}
    >
      <Link to={to} onClick={clickHandler}>{title}</Link>
    </div>
  )
}
