import React from 'react'
import { Table } from 'antd'
import { observer } from 'mobx-react-lite'
import { ReportsStore } from 'store/reports.store'
import { columns } from './sales-columns'
import './styles.scss'

export const SalesReportFeature = observer(() => (
  <Table
    columns={columns}
    dataSource={ReportsStore.salesReport}
    pagination={false}
    expandable={{
      indentSize: 300,
      expandedRowRender: (record) => (
        <table>
          <thead>
            <tr>
              <th style={{ width: '30%' }} />
              <th>Price</th>
              <th>Quantity</th>
              <th>Total</th>
            </tr>
          </thead>
          {record?.items?.map((x) => (
            <tr key={x.productId}>
              <td style={{ width: '30%' }} />
              <td>{x.price}</td>
              <td>{x.quantity}</td>
              <td>{x.total}</td>
            </tr>
          ))}
        </table>
      ),
    }}
  />
))
