import React, { useEffect, useState } from 'react'
import { DatePicker } from 'antd'
import { observer } from 'mobx-react-lite'
import moment, { Moment } from 'moment/moment'
import { DailyReportFeature } from 'features/report/today'
import { CommonPage } from 'shared/layout/CommonPage'
import { getUiDate } from 'shared/dates/formatters'
import { Preloader } from 'shared/components/preloader'
import { DashboardViewModel } from './dashboard.view-model'

const DashboardPage = observer(() => {
  const [date, setDate] = useState<Moment>(moment())
  const from = date.clone().startOf('day').toISOString()
  const to = date.clone().endOf('day').toISOString()

  useEffect(() => {
    DashboardViewModel.init(from, to)
  }, [from, to])

  return DashboardViewModel.loading ? (
    <Preloader message="Loading Today Stats" lower />
  ) : (
    <CommonPage
      isMounted={!DashboardViewModel.loading}
      key={`dashboard_${date.toISOString() + Math.random()}`}
      title={getUiDate(date.toISOString())}
    >
      <DailyReportFeature from={from} to={to} />
    </CommonPage>
  )
})

export default DashboardPage
