import * as React from 'react'
import { Form, Input, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { ReadProductCategoryDto, ReadSalePointDto } from '../../../api-client'

const { Option } = Select

interface ProductAddFormProps {
  setFormData: (formData: any) => void
  productCategories: Array<ReadProductCategoryDto> | undefined
  salePoints: Array<ReadSalePointDto> | undefined
  formData?: any
}

function ProductAddForm({
  formData,
  setFormData,
  productCategories,
  salePoints,
}: ProductAddFormProps) {
  return (
    <Form
      initialValues={{
          ...formData,
            categoryId: formData?.categories[0]?.id,
            salePointId: formData?.salePoint?.id,
      }}
      labelCol={{
        xs: 24,
        sm: 6,
      }}
      onValuesChange={(changed: any, values: any) => {
        setFormData({ ...values, ...changed })
      }}
    >
      <Form.Item
        label="Product name"
        name="title"
        rules={[
          {
            required: true,
            message: 'Enter product name',
          },
        ]}
      >
        <Input autoFocus />
      </Form.Item>
      <Form.Item label="Product description" name="description">
        <TextArea />
      </Form.Item>

      <Form.Item
        label="Category"
        name="categoryId"
        rules={[
          {
            required: true,
            message: 'Select category',
          },
        ]}
      >
        <Select>
          {productCategories &&
            productCategories.map((item: ReadProductCategoryDto) => (
              <Option key={`prod_cat_opt_${item.id}`} value={item.id}>
                {item.title}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Sale point"
        name="salePointId"
        rules={[
          {
            required: true,
            message: 'Select Sale Point',
          },
        ]}
      >
        <Select>
          {salePoints &&
            salePoints.map((item: ReadSalePointDto) => (
              <Option key={`salepoint_opt_${item.id}`} value={item.id}>
                {item.title}
              </Option>
            ))}
        </Select>
      </Form.Item>
    </Form>
  )
}

export default ProductAddForm
