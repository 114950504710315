import { ColumnsType } from 'antd/es/table'
import * as React from 'react'
import type {
  ReadMemberDto,
  ReadWarehouseItemDto,
  ReadWarehouseJournalDto,
} from 'api-client'
import { WarehouseJournalType } from 'api-client'
import { getUiDateTime } from 'shared/dates/formatters'
import { Button } from 'shared/components'
import { WarehouseStore } from 'store/warehouse.store'
import { subs } from 'shared/utils/math'
import { comments } from './warehouse-comments-column'

export const columns: ColumnsType<ReadWarehouseJournalDto> = [
  {
    title: 'Date',
    dataIndex: 'dateCreated',
    render: (item) => <div>{getUiDateTime(item)}</div>,
    width: '80px',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    render: (item: number) => (
      <div
        style={{
          color: item < 0 ? '#ff0000' : '#000',
          wordBreak: 'break-all',
        }}
      >
        {item}
      </div>
    ),
    width: '80px',
  },
  {
    title: 'Last Quantity',
    dataIndex: 'lastQuantity',
    render: (item: number) => (
      <div
        style={{
          color: item < 0 ? '#ff0000' : '#000',
          wordBreak: 'break-all',
        }}
      >
        {item}
      </div>
    ),
    width: '80px',
  },
  {
    title: 'Difference',
    render: (item: ReadWarehouseJournalDto) => (
      // @ts-ignore
      <div style={{ wordBreak: 'break-all' }}>{subs(item?.quantity, item?.lastQuantity)}</div> // eslint-disable-line no-unsafe-optional-chaining
    ),
    width: '80px',
  },
  {
    title: 'Close Quantity',
    render: (item) => {
      const quantity = item.quantity - item.lastQuantity
      return (
        item?.isClosed && <div style={{ color: `${quantity < 0 ? 'red' : ''}` }}>{quantity}</div>
      )
    },
    width: '80px',
  },
  {
    title: 'Price',
    dataIndex: 'warehouseItem',
    render: (item: ReadWarehouseItemDto) => <div>{item.price} cred.</div>,
  },
  {
    title: 'Sum',
    render: (item: ReadWarehouseJournalDto) =>
      !item.isClosed && (
        // @ts-ignore
        <div style={{ wordBreak: 'break-all' }}>{item?.orders[0]?.journalEntry?.count} </div> // eslint-disable-line no-unsafe-optional-chaining
      ),
    width: '80px',
  },
  {
    title: 'Discount',
    render: (item: ReadWarehouseJournalDto) =>
      // @ts-ignore
      !item.isClosed && <div style={{ wordBreak: 'break-all' }}>{item?.orders[0]?.discount} </div>, // eslint-disable-line no-unsafe-optional-chaining
    width: '80px',
  },
  {
    title: 'Member',
    dataIndex: 'member',
    render: (item: ReadMemberDto) => (
      <div>
        <span>{item?.firstName} </span>
        <span>{item?.lastName}</span>
      </div>
    ),
    width: '80px',
  },
  {
    title: 'Assigned',
    dataIndex: 'assignedByUser',
    render: (item) => (
      <div>
        <span>{item?.firstName} </span>
        <span>{item?.lastName}</span>
      </div>
    ),
    width: '100px',
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    width: '80px',
  },
  comments(false),
  {
    title: 'Revert',
    render: (item: ReadWarehouseJournalDto) => (
      <div>
        {/* eslint-disable-next-line no-underscore-dangle */}
        {item && !item.isReverted && item.type === WarehouseJournalType._1 && (
          <Button
            small
            onClick={() => {
              WarehouseStore.revertReleaseProducts(item.id!)
            }}
          >
            Revert Operation
          </Button>
        )}
      </div>
    ),
    width: '80px',
  },
]
