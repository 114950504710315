import React, { useState } from 'react'
import styled from 'styled-components'
import { CloseBatchTriggerProps } from '../store-batch/types'
import { CloseBatchModal } from './close-batch.modal'

const Trigger = styled.span`
  display: inline-block;
`

export function CloseBatchTrigger({
  onSubmit,
  batch,
  children,
  productTitle,
}: CloseBatchTriggerProps) {
  const [show, setShow] = useState<boolean>(false)
  return (
    <div>
      {show && (
        <CloseBatchModal
          productTitle={productTitle}
          batch={batch}
          onSubmit={onSubmit}
          onClose={() => {
            setShow(false)
          }}
        />
      )}
      <Trigger onClick={() => setShow(true)}>{children}</Trigger>
    </div>
  )
}
