import React, { ReactNode, useState, MouseEvent, KeyboardEvent } from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import Icon, { IconName } from 'shared/components/icon'
import { NavigationItemChild } from 'shared/components/navigation/NavigationItemChild'
import { isMobile } from 'react-device-detect'
import './NavigationItem.scss'
import { AppStore } from '../../../store/app.store'

export type NavigationItemTheme = 'manager' | 'admin' | 'area-admin' | 'customer'

export interface NavigationItemProps {
  to: string
  active?: boolean
  icon?: IconName
  postfix?: ReactNode
  theme?: NavigationItemTheme
  disabled?: boolean
  items?: Array<any>
  children?: ReactNode
  isCollapsed?: boolean
}

export function NavigationItem(props: NavigationItemProps) {

  const clickHandler = () => {
    if (isMobile) {
      AppStore.setSidebarHidden()
    }
  }

  const {
    to,
    active = false,
    children,
    icon,
    postfix,
    theme = 'patient',
    disabled = false,
    items,
    isCollapsed = false
  } = props
  const [showItems, setShowItems] = useState<boolean>(!isCollapsed)
  const classNames = classnames(
    'navigation-item',
    active && 'navigation-item_active',
    `navigation-item_${theme}`,
    disabled && 'navigation-item_disabled'
  )
  const toggleShowItems = (e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault()
    setShowItems(!showItems)
  }

  const linkContent = (
    <div className="navigation-item-container">
      {icon && (
        <div className="navigation-item__icon">
          <Icon name={icon} />
        </div>
      )}

      <div className="navigation-item__text">{children}</div>

      {items?.length && (
        <div
          className="navigation-item__postfix"
          tabIndex={-1}
          role="button"
          onKeyDown={(e) => toggleShowItems(e)}
          onClick={(e) => toggleShowItems(e)}
        >
          {showItems && <Icon name="minus" color="#DE6438" />}
          {!showItems && <Icon name="plus" color="#DE6438" />}
        </div>
      )}
      {postfix && !disabled && <div className="navigation-item__postfix">{postfix}</div>}
      {disabled && (
        <div className="navigation-item__postfix">
          <Icon name="navigation-lock" />
        </div>
      )}
    </div>
  )

  return (
    <div className="navigation-item-expandable">
      <div className={classNames}>
        {disabled && <div className="navigation-item-wrapper">{linkContent}</div>}
        {!disabled && (
          <Link className="navigation-item-wrapper" to={to} onClick={clickHandler}>
            {linkContent}
          </Link>
        )}
      </div>

      {showItems && items?.length && (
        <div className="navigation-item-items">
          {items?.map((item) => (
            <NavigationItemChild
              key={`navigation-item_${item.path}`}
              to={item.path}
              title={item.title}
            />
          ))}
        </div>
      )}
    </div>
  )
}
