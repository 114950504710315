import * as React from 'react'
import { useState } from 'react'
import { Button } from 'shared/components'
import { observer } from 'mobx-react-lite'
import { ProductCategoriesStore } from 'store/product-categories.store'
import { Preloader } from 'shared/components/preloader'
import ProductCategoryAddForm from './product-category-add.form'

interface ProductCategoryAddFeatureProps {
  onSuccess?: () => void
}

const ProductCategoryAddFeature: React.FC<ProductCategoryAddFeatureProps> = observer(
  ({ onSuccess }) => {
    const [formData, setFormData] = useState({})

    const createProductCategory = () =>
      formData &&
      ProductCategoriesStore.createCategory(formData).then(() => onSuccess && onSuccess())

    return (
      <div>
        {ProductCategoriesStore.loadStatus.isLoading && <Preloader />}
        <ProductCategoryAddForm setFormData={setFormData} formData={formData} />
        <Button
          primary
          onClick={() => {
            createProductCategory()
          }}
        >
          Create
        </Button>
      </div>
    )
  }
)

export default ProductCategoryAddFeature
