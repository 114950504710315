import React, { ReactNode } from 'react'
import './Navigation.scss'

export interface NavigationProps {
  children?: ReactNode
}

export function Navigation(props: NavigationProps) {
  const { children } = props

  const clickHandler = () => {
  }


  return (
    <div className="navigation">
      <div
        role="button"
        tabIndex={0}
        onClick={clickHandler}
        onKeyDown={clickHandler}
        className="navigation-wrapper"
      >
        {children}
      </div>
    </div>
  )
}
