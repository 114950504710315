import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx'
import * as R from 'ramda'
import type {
  CloseBatchDto,
  CreateWarehouseJournalThreadDto,
   GetWarehouseJournalRecordsDto,
  ReadWarehouseItemDto,
  ReadWarehouseJournalDto,
  RechargeBatchDto,
  RegisterWasteDto,
  WarehouseItemReadShortDto,
} from 'api-client'
import { Effect } from './utils/Effect'
import { RequestHelper } from './utils/RequestHelper'
import ApiClient from '../api'
import { AreasStore } from './areas.store'
import { BalanceStore } from './balance.store'
import { UserStore } from './user.store'
import { CashierStore } from './cashier.store'

const updateCommentsInJournal = (
  list: Array<ReadWarehouseJournalDto>,
  data: ReadWarehouseJournalDto,
  setList: (list: Array<ReadWarehouseJournalDto>) => void
) => {
  const recordIndex = list.findIndex((j) => j.id === data.id)
  const newList = [...list]
  newList[recordIndex] = data
  setList(newList)
}

type BatchStatus = 'OPENED' | 'CLOSED'

class WarehouseStoreClass {
  constructor() {
    makeAutoObservable(this)
  }

  updateCommentsInJournal = updateCommentsInJournal

  loading: boolean = false

  @observable filterProductTitle: string | null = null

  releasingProducts: boolean = false

  @action setFilterProductTitle = (query: string | null) => {
    this.filterProductTitle = query?.replace(/\s+/g, '').toLowerCase() || null
  }

  @observable searchProductTitle: string = ''

  @action setSearchProductTitle = (query: string | null) => {
    this.searchProductTitle = query?.replace(/\s+/g, '').toLowerCase() || ''
  }

  @observable filterMember: string | null = null

  @action setFilterMember = (query: string | null) => {
    this.filterMember = query?.replace(/\s+/g, '').toLowerCase() || null
  }

  @observable filter: string = ''

  @observable filterStatus: BatchStatus = 'OPENED'

  @observable closedStockPageSize: number = 50

  @observable closedStockCurrentPage: number = 1

  @action
  public setClosedStockCurrentPage(page: number) {
    this.closedStockCurrentPage = page
  }

  @action
  public setClosedStockPageSize(size: number) {
    this.closedStockPageSize = size
  }

  @action setFilterStatus = (status: BatchStatus) => {
    this.filterStatus = status
  }

  @observable filterSalePoint: Array<string> | null = null

  @action setFilterSalePoint = (salePoints: Array<string>) => {
    this.filterSalePoint = salePoints
  }

  @observable currentCategoryId: string | null = ''

  @action setCategoryId = (id: string | null) => {
    runInAction(() => {
      this.currentCategoryId = id
    })
  }

  @computed get categories() {
    return R.pipe(
      R.map((p: ReadWarehouseItemDto) => p.product?.categories),
      R.uniq,
      R.flatten,
      R.sortBy(R.prop('sortOrder'))
    )(this.productsSliced)
  }

  filterProductsBySalePoint = (item: ReadWarehouseItemDto) => {
    if (this.filterSalePoint?.length) {
      return this.filterSalePoint.includes(item?.product!.salePoint!.id!)
    }
    return true
  }

  filterProductsByCategory = (item: ReadWarehouseItemDto) => {
    if (this.currentCategoryId) {
      const categoryId = R.head(item?.product!.categories!)?.id
      return this.currentCategoryId?.includes(categoryId!)
    }
    return true
  }

  @computed get filteredProducts(): Array<ReadWarehouseItemDto> {
    const filterProducts = (item: ReadWarehouseItemDto) => {
      if (this.filterProductTitle) {
        const query = this.filterProductTitle.replace(/\s+/g, '').toLowerCase()
        return item?.product!.title!.toLowerCase().includes(query)
      }
      return true
    }

    return R.pipe(
      R.filter(filterProducts),
      R.filter(this.filterProductsBySalePoint),
      R.filter(this.filterProductsByCategory)
    )(this.productsSliced)
  }

  @computed get filteredProductsClosed(): Array<ReadWarehouseItemDto> {
    return R.pipe(
        R.filter(this.filterProductsBySalePoint),
        R.filter(this.filterProductsByCategory)
    )(this.productsSlicedClosed)
  }

  loadAllEffect = new Effect(
    ({ salePointId, isListed }) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().warehouse.getApiV1WarehouseByArea(AreasStore.selectedAreaId, isListed),
        'Products were loaded'
      ),
    '',
    true
  )

  loadAll = async () => {
    await this.loadAllEffect.run({
      salePointId: null,
      isListed: false,
    })
  }

  loadClosedEffect = new Effect(
    (searchTerm, pageNumber, pageSize) =>
      RequestHelper.unwrapListFromFetchResponse(
        ApiClient().warehouse.getApiV1WarehouseByAreaClosed(
          AreasStore.selectedAreaId,
          searchTerm,
          pageNumber,
          pageSize
        )
      ),
    '',
    true
  )

  loadClosed = async (
    searchTerm?: string,
    pageNumber?: number,
    pageSize: number = this.closedStockPageSize
  ) => {
    await this.loadClosedEffect.run(searchTerm, pageNumber, pageSize)
  }

  get products() {
    return this.loadAllEffect.data ?? []
  }

  get productsClosed() {
    return this.loadClosedEffect.data?.data ?? []
  }

  get totalCountClosed() {
    return this.loadClosedEffect.data?.total ?? 0
  }

  @action setFilterValue = (value: string) => {
    this.filter = value
  }

  get productsSliced() {
    return this.products.slice()
  }

  get productsSlicedClosed() {
    return this.productsClosed.slice()
  }

  loadJournalEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().warehouse.postApiV1WarehouseJournal(request)
      ),
    'Loading Warehouse Journal'
  )

  loadJournal = async (from: string, to: string) => {
    const response = await this.loadJournalEffect.run({
      from,
      to,
      areaId: AreasStore.selectedAreaId,
    })
    return response
  }

  get journal() {
    return this.loadJournalEffect.data?.slice() || []
  }

  get filteredJournal() {
    return this.journal
      .filter((j) => {
        if (!this.filterProductTitle) return true
        return j.warehouseItem?.product?.title?.toLowerCase().includes(this.filterProductTitle!)
      })
      .filter((j) => {
        const query = this.filterMember
        if (!query) return true
        const item = j.member
        const fullName = `${item?.firstName}${item?.lastName}`
          .toLowerCase()
          .replace(/\s+/g, '')
          .toLowerCase()
        const fullNameReversed = `${item?.lastName}${item?.firstName}`
          .toLowerCase()
          .replace(/\s+/g, '')
          .toLowerCase()
        return fullName.includes(query) || fullNameReversed.includes(query)
      })
  }

  setJournal = (journal?: Array<ReadWarehouseJournalDto> | null | undefined) => {
    this.loadJournalEffect.data = journal
  }

  revertReleaseProductsEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().warehouse.postApiV1WarehouseRevertReleaseProducts(request),
        'Products Released Successfully'
      ),
    'Reverting Operation.'
  )

  revertReleaseProducts = async (warehouseJournalId: string, setData?: (data: any) => void) => {
    const response = await this.revertReleaseProductsEffect.run({
      warehouseJournalId,
      areaId: AreasStore.selectedAreaId,
    })
    if (response) {
      // TODO: Update Orders journal on member profile page
      // const updatedEntry = R.pipe(
      //   R.filter<ReadWarehouseJournalDto>((j) => j.id === warehouseJournalId),
      //   R.prepend(response)
      // )(this.getJournal)
      this.setJournal([response, ...this.getJournal])
      const sum = R.pathOr(0, ['orders', 0, 'journalEntry', 'count'], response)
      BalanceStore.addToBalance(sum)
      if (setData) setData(response)
    }
    return response
  }

  releaseProductsEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().warehouse.postApiV1WarehouseReleaseProducts(request),
        'Products Released Successfully'
      ),
    'Releasing Products.'
  )

  releaseProducts = async (
    products: Array<WarehouseItemReadShortDto>,
    memberId: string,
    discount: number
  ) => {
    this.releasingProducts = true
    const response = await this.releaseProductsEffect.run({
      products,
      memberId,
      discount,
      areaId: AreasStore.selectedAreaId,
    })
    if (response) {
      const productsForUpdate =
        CashierStore.products?.map((p) => {
          const change = response.products?.find((i) => i.id === p.id)
          if (change) {
            return {
              ...p,
              quantity: change.quantity,
            }
          }
          return p
        }) ?? null
      BalanceStore.reduceCurrentBalance(response.sum!)
      CashierStore.setProducts(productsForUpdate)
    }
    this.releasingProducts = false
    return response
  }

  storeBatchEffect = new Effect((request) =>
    RequestHelper.unwrapFromFetchResponse(ApiClient().warehouse.postApiV1WarehouseStore(request))
  )

  storeNewBatch = async (productId: string, quantity: number, price: number) => {
    await this.storeBatchEffect.run({
      productId,
      quantity,
      price,
      areaId: AreasStore.selectedAreaId,
    })
  }

  setIsListedEffect = new Effect((request) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().warehouse.postApiV1WarehouseSetIsListed(request),
      'Warehouse batch listins state changed.'
    )
  )

  setIsListed = async (warehouseItemId: string, isListed: boolean) => {
    const response = await this.setIsListedEffect.run({
      warehouseItemId,
      isListed,
    })
    if (response) {
      this.updateProduct(warehouseItemId, response)
    }
  }

  loadWarehouseBatchEffect = new Effect(
    (id) => RequestHelper.unwrapFromFetchResponse(ApiClient().warehouse.getWarehouseItem(id)),
    '',
    true
  )

  loadWarehouseBatch = async (id: string) => {
    await this.loadWarehouseBatchEffect.run(id)
  }

  registerWasteEffect = new Effect((request) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().warehouse.postApiV1WarehouseRegisterWaste({
        ...request,
        areaId: AreasStore.selectedAreaId,
      })
    )
  )

  registerWaste = async (request: RegisterWasteDto) => {
    const response = await this.registerWasteEffect.run(request)
    if (response) {
      this.loadWarehouseBatchEffect.data = response
    }
  }

  rechargeBatchEffect = new Effect((request) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().warehouse.postApiV1WarehouseRechargeBatch({
        ...request,
        areaId: AreasStore.selectedAreaId,
      })
    )
  )

  rechargeBatch = async (request: RechargeBatchDto) => {
    const response = await this.rechargeBatchEffect.run(request)
    if (response) {
      this.loadWarehouseBatchEffect.data = response
    }
  }

  closeWarehouseBatchEffect = new Effect((request) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().warehouse.postApiV1WarehouseCloseBatch(request)
    )
  )

  closeWarehouseBatch = async (request: CloseBatchDto) => {
    const response = await this.closeWarehouseBatchEffect.run({
      ...request,
      areaId: AreasStore.selectedAreaId,
    })
    if (response) {
      this.loadWarehouseBatchEffect.data = response
    }
  }

  loadWarehouseBatchJournalEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().warehouse.postApiV1WarehouseJournalFiltered(request)
      ),
    '',
    true
  )

  loadWarehouseBatchJournal = async (request: GetWarehouseJournalRecordsDto) => {
    await this.loadWarehouseBatchJournalEffect.run(request)
  }

  getBatchesByProductEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().warehouse.postApiV1WarehouseGetByProduct(request)
      ),
    'Loading Warehouse Batches'
  )

  getBatchesByProduct = async (productId: string) => {
    const response = await this.getBatchesByProductEffect.run({
      productId,
      areaId: AreasStore.selectedAreaId,
    })
    return response
  }

  get batchesByProduct() {
    return this.getBatchesByProductEffect.data || []
  }

  mergeBatchIntoEffect = new Effect(
    (request) =>
      RequestHelper.unwrapFromFetchResponse(
        ApiClient().warehouse.postApiV1WarehouseMergeBatchInto(request)
      ),
    'Loading Warehouse Batches'
  )

  mergeBatchInto = async (parentBatchId: string, childBatchId: string) => {
    const response = await this.mergeBatchIntoEffect.run({
      parentBatchId,
      childBatchId,
      areaId: AreasStore.selectedAreaId,
    })
    if (response) {
      this.loadWarehouseBatchEffect.data = response
    }
    return response
  }

  get journalForCurrentBatch() {
    return this.loadWarehouseBatchJournalEffect.data || []
  }

  setJournalForCurrentBatch = (journal: Array<ReadWarehouseJournalDto>) => {
    this.loadWarehouseBatchJournalEffect.data = journal
  }

  get warehouseBatch(): ReadWarehouseItemDto {
    const { data } = this.loadWarehouseBatchEffect
    return data ?? { id: '' }
  }

  updateProduct = (id: string, data?: ReadWarehouseItemDto | null | undefined) => {
    const index = this.products!.findIndex((p) => p.id === id)
    if (data) {
      this.loadAllEffect.data!.splice(index, 1, data)
    }
  }

  addCommentToJournalEffect = new Effect((request) =>
    RequestHelper.unwrapFromFetchResponse(
      ApiClient().warehouse.postApiV1WarehouseAddJournalComment(request)
    )
  )

  addCommentToJournal = async (request: CreateWarehouseJournalThreadDto, fullList: boolean) => {
    const response = await this.addCommentToJournalEffect.run({
      ...request,
      userId: UserStore.userKeycloakId,
    })
    if (response) {
      if (fullList) {
        this.updateCommentsInJournal(this.journal, response, this.setJournal)
      } else {
        this.updateCommentsInJournal(
          this.journalForCurrentBatch,
          response,
          this.setJournalForCurrentBatch
        )
      }
    }
  }

  get getJournal() {
    return this.journal ?? []
  }

  get hasJournal() {
    return !!this.journal?.length
  }

  startLoading = () => {
    this.loading = true
  }

  stopLoading = () => {
    this.loading = false
  }
}

export const WarehouseStore = new WarehouseStoreClass()
