// @ts-nocheck
import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { AuthenticatedTemplate } from 'auth/AuthenticatedTemplate'
import { observer } from 'mobx-react-lite'
import { DefaultPageRedirect } from 'router/DefaultPageRedirect'
import { RoleBasedRoutes } from 'router/RoleBasedRoutes'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from '../keycloak'
import { AppInit } from '../AppInit'
import { ApiLoading } from '../shared/components/api-loading/ApiLoading'
import { ApiErrorHandler } from '../shared/components/error-handler/ApiErrorHandler'
import { CustomMessageHandler } from '../shared/components/error-handler/CustomMessageHandler'
import { privateWebAppPath } from './constants'
import PublicRoutes from '../modules/public/routes'
import { tokensHandler } from '../auth/authenticationService'

interface AppRouterProps {
  redirectUrl?: string
}

export const AppRouter: React.FC<AppRouterProps> = observer(({ redirectUrl }) => (
  <BrowserRouter>
    <Switch>
      <Route path={privateWebAppPath}>
        <ReactKeycloakProvider
          authClient={keycloak}
          onTokens={tokensHandler}
          initOptions={{
            onLoad: 'check-sso',
            silent: true,
            silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
            pkceMethod: 'S256',
            checkLoginIframe: true,
          }}
        >
          <React.StrictMode>
            <AppInit>
              <ApiLoading />
              <ApiErrorHandler />
              <CustomMessageHandler />
              <AuthenticatedTemplate>
                <RoleBasedRoutes />
                <Route path="">
                  <DefaultPageRedirect redirectUrl={redirectUrl} />
                </Route>
              </AuthenticatedTemplate>
            </AppInit>
          </React.StrictMode>
        </ReactKeycloakProvider>
      </Route>
      <Route path="/">
        <PublicRoutes />
      </Route>
    </Switch>
  </BrowserRouter>
))
