/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BestMemberConsumptionResponseDtoApiResponse } from '../models/BestMemberConsumptionResponseDtoApiResponse';
import type { ConsumptionByCategoryResponseDtoListApiResponse } from '../models/ConsumptionByCategoryResponseDtoListApiResponse';
import type { GetMemberBalanceMovements } from '../models/GetMemberBalanceMovements';
import type { GetMemberQuoteJournalDto } from '../models/GetMemberQuoteJournalDto';
import type { GetMoneyIncomeItemDtoListApiResponse } from '../models/GetMoneyIncomeItemDtoListApiResponse';
import type { GetVisitsHistoryDto } from '../models/GetVisitsHistoryDto';
import type { Int32ApiResponse } from '../models/Int32ApiResponse';
import type { MemberQuoteJournalReadDtoListApiResponse } from '../models/MemberQuoteJournalReadDtoListApiResponse';
import type { OrderIEnumerableApiResponse } from '../models/OrderIEnumerableApiResponse';
import type { OrderListApiResponse } from '../models/OrderListApiResponse';
import type { ReadMemberVisitDtoIEnumerableApiResponse } from '../models/ReadMemberVisitDtoIEnumerableApiResponse';
import type { ReadWarehouseJournalDtoApiResponse } from '../models/ReadWarehouseJournalDtoApiResponse';
import type { ReadWarehouseJournalInfoDtoListApiResponse } from '../models/ReadWarehouseJournalInfoDtoListApiResponse';
import type { ReportByPeriodDto } from '../models/ReportByPeriodDto';
import type { SalesReportDtoListApiResponse } from '../models/SalesReportDtoListApiResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReportsService {

    /**
     * @param id
     * @param areaId
     * @returns OrderIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static getApiV1ReportsBalanceTransactionsDeposit(
        id: string,
        areaId?: string,
    ): CancelablePromise<OrderIEnumerableApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/reports/balance-transactions-deposit/{id}',
            path: {
                'id': id,
            },
            query: {
                'areaId': areaId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns ReadMemberVisitDtoIEnumerableApiResponse Success
     * @throws ApiError
     */
    public static postApiV1ReportsVisitsByPeriod(
        requestBody?: GetVisitsHistoryDto,
    ): CancelablePromise<ReadMemberVisitDtoIEnumerableApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/reports/visits-by-period',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns Int32ApiResponse Success
     * @throws ApiError
     */
    public static postApiV1ReportsNewMembersByPeriod(
        requestBody?: ReportByPeriodDto,
    ): CancelablePromise<Int32ApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/reports/new-members-by-period',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns MemberQuoteJournalReadDtoListApiResponse Success
     * @throws ApiError
     */
    public static postApiV1ReportsMemberQuoteJournal(
        requestBody?: GetMemberQuoteJournalDto,
    ): CancelablePromise<MemberQuoteJournalReadDtoListApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/reports/member-quote-journal',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns OrderListApiResponse Success
     * @throws ApiError
     */
    public static postApiV1ReportsMemberBalanceMovements(
        requestBody?: GetMemberBalanceMovements,
    ): CancelablePromise<OrderListApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/reports/member-balance-movements',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns ReadWarehouseJournalDtoApiResponse Success
     * @throws ApiError
     */
    public static getApiV1ReportsOrderInfo(
        id?: string,
    ): CancelablePromise<ReadWarehouseJournalDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/reports/order-info',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody
     * @returns BestMemberConsumptionResponseDtoApiResponse Success
     * @throws ApiError
     */
    public static postApiV1ReportsBestMemberConsumption(
        requestBody?: ReportByPeriodDto,
    ): CancelablePromise<BestMemberConsumptionResponseDtoApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/reports/best-member-consumption',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns ConsumptionByCategoryResponseDtoListApiResponse Success
     * @throws ApiError
     */
    public static postApiV1ReportsConsumptionByCategory(
        requestBody?: ReportByPeriodDto,
    ): CancelablePromise<ConsumptionByCategoryResponseDtoListApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/reports/consumption-by-category',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns GetMoneyIncomeItemDtoListApiResponse Success
     * @throws ApiError
     */
    public static postApiV1ReportsMoneyIncome(
        requestBody?: ReportByPeriodDto,
    ): CancelablePromise<GetMoneyIncomeItemDtoListApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/reports/money-income',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns ReadWarehouseJournalInfoDtoListApiResponse Success
     * @throws ApiError
     */
    public static postApiV1ReportsBatchesArival(
        requestBody?: ReportByPeriodDto,
    ): CancelablePromise<ReadWarehouseJournalInfoDtoListApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/reports/batches-arival',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns SalesReportDtoListApiResponse Success
     * @throws ApiError
     */
    public static postApiV1ReportsSales(
        requestBody?: ReportByPeriodDto,
    ): CancelablePromise<SalesReportDtoListApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/reports/sales',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
