import { ColumnsType } from 'antd/es/table'
import { SalesReportDto } from 'api-client'

export const columns: ColumnsType<SalesReportDto> = [
  {
    title: 'Product',
    key: 1,
    dataIndex: 'productTitle',
  },
  {
    title: 'Total Quantity',
    key: 1,
    dataIndex: 'totalQuantity',
  },
  {
    title: 'Total Profit',
    key: 3,
    dataIndex: 'totalProfit',
  },
]
