// @ts-nocheck
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { SignaturePage } from '../customer/pages/signature.page'
import { RegistrationPage } from '../customer/pages/registration.page'

function PublicRoutes() {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/app" />
      </Route>
      <Route path="/signature/:areaId">
        <SignaturePage />
      </Route>
      <Route exact path="/registration/:areaId">
        <RegistrationPage />
      </Route>
    </Switch>
  )
}

export default PublicRoutes
