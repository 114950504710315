import React, { ChangeEvent, useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import styled from 'styled-components'
import { RechargeBatchFormData } from './types'

interface RegisterWasteFormProps {
  setFormData: (formData: RechargeBatchFormData) => void
}

const FormRow = styled.div`
  width: 100%;
  margin-bottom: 15px;
`
const Input = styled.input`
  width: 100%;
  font-size: 16px;
  padding: 5px;
`

export function RechargeBatchForm({ setFormData }: RegisterWasteFormProps) {
  const [quantity, setQuantity] = useState<string>('0')

  useEffect(() => {
    setFormData({
      quantity,
    })
  }, [quantity, setFormData])

  return (
    <form>
      <FormRow>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor="quantity">
          <Row>
            <Col xs={8}>Recharge amount, grams</Col>
            <Col xs={16}>
              <Input
                id="quantity"
                value={quantity}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setQuantity(e.target.value)
                }}
              />
            </Col>
          </Row>
        </label>
      </FormRow>
    </form>
  )
}
